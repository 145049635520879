var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-center align-center",style:(_vm.computedHeight)},[_c('GmapMap',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingMap),expression:"loadingMap"}],ref:"mapRef",style:(_vm.computedHeight),attrs:{"center":_vm.origin,"zoom":15,"clickable":true,"draggable":true,"zoomControl":true,"options":_vm.computedOptions}},[_vm._l((_vm.stops),function(stop,index){return _c('GmapMarker',{key:stop.index,ref:("marker" + index),refInFor:true,attrs:{"position":stop,"animation":2,"icon":_vm.markerOptions(index)}})}),_c('GmapPolyline',{attrs:{"path":_vm.stops,"options":_vm.options}}),(
        _vm.viewMap &&
        _vm.trip.providerLocation.length > 0 &&
        _vm.dataProp.status !== 'Completed'
      )?_c('GmapCustomMarker',{attrs:{"marker":{
        lat: _vm.trip.providerLocation[0],
        lng: _vm.trip.providerLocation[1],
      }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({style:(("transform: rotate(" + (_vm.trip.bearing) + "deg);")),attrs:{"src":_vm.markerOptionsCarTest.url,"alt":""}},'img',attrs,false),on))]}}],null,false,616552787)},[_c('span',[_c('ul',[_c('li',[_vm._v("ID viaje: "+_vm._s(_vm.trip.unique_id))]),(_vm.trip.is_provider_status === 6)?_c('li',[_vm._v(" Usuario: "+_vm._s(_vm.trip.user_first_name + " " + _vm.trip.user_last_name)+" ")]):_vm._e(),_c('li',[_vm._v(" Conductor: "+_vm._s(_vm.trip.provider_first_name + " " + _vm.trip.provider_last_name)+" ")]),_c('li',[_vm._v("Status: "+_vm._s(_vm.$t(_vm.dataProp.status)))])])])])],1):_vm._e(),(_vm.viewMap && _vm.dataProp.status === 'Completed')?_c('GmapPolyline',{attrs:{"path":_vm.computedPath,"options":_vm.optionsPath}}):_vm._e()],2),(!_vm.loadingMap)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":64,"color":"primary"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }